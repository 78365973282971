import { Plugin } from '@nuxt/types'
import { getModule } from 'nuxt-property-decorator'

import getAuthApi from './modules/auth'
import gwtWidgetsApi from './modules/widgets'
import getPaymentsApi from './modules/payments'
import getEmailTemplatesApi from './modules/email-templates'
import getSubscribersApi from './modules/subscribers'
import getDiscountGroupsApi from './modules/discount-groups'
import getConfigsApi from './modules/config'
import getCurrenciesApi from './modules/currencies'
import getBannerBlocksApi from './modules/banner-blocks'
import getRolesApi from './modules/roles'
import getSeoPagesApi from './modules/seo-pages'
import getSitesApi from './modules/sites'
import getPriceRolesApi from './modules/price-roles'
import getPriceRoleAssignmentApi from './modules/price-role-assignment'
import getPartnersApi from './modules/partners'
import getStickersApi from './modules/stickers'
import getProductGroupsApi from './modules/product-groups'
import getProductsApi from './modules/products'
import getDeliveryGroupsApi from './modules/delivery-groups'
import getArchiveApi from './modules/archive'
import getDeliveryApi from './modules/delivery'
import geRobotsApi from './modules/robots'
import getCategoriesApi from './modules/categories'
import getOrdersApi from './modules/orders'
import getWordReplacesApi from './modules/word-replaces'
import getSearchTextReplaceApi from './modules/search-text-replace'
import getWordPluralsApi from './modules/word-plurals'
import getWordCasesApi from './modules/word-cases'
import getWordAdjectivesApi from './modules/word-adjectives'
import getTemplatesApi from './modules/templates'
import getGenerateApi from './modules/generate'
import getPermissionsApi from './modules/permissions'
import getReviewsApi from './modules/reviews'
import getListWaitingApi from './modules/list-waiting'
import getOrderStatusesApi from './modules/order-statuses'
import getOrderStatusGroupsApi from './modules/order-status-groups'
import getUsersApi from './modules/users'
import getMainPageApi from './modules/main-page'
import getRegionsApi from './modules/regions'
import getFormsApi from './modules/forms'
import getFormParamsApi from './modules/form-params'
import getDeliveryPricesApi from './modules/delivery-prices'
import getFilesApi from './modules/files'
import getOptionsValuesApi from './modules/options-values'
import getNewsApi from './modules/news'
import getPointsApi from './modules/points'
import getProductOffersApi from './modules/product-offers'
import getBasketsApi from './modules/baskets'
import getPagesApi from './modules/pages'
import getOrderBillsApi from './modules/order-bills'
import getOffersApi from './modules/offer'
import getOrderOffersApi from './modules/order-offers'
import getFixedProductsApi from './modules/product-fixed'
import getFormatApi from './modules/format'
import getReindexerApi from './modules/reindexer'
import getBonusApi from './modules/bonus'
import getBonusAccountApi from './modules/bonus-account'
import getBonusTypeApi from './modules/bonus-type'
import getBonusLogApi from './modules/bonus-log'
import getErrorLogsApi from './modules/error-logs'
import getSeoTextsApi from './modules/seo-texts'
import getFeedsApi from './modules/feeds'
import getPromoCodesApi from './modules/promo-codes'
import getCustomFiltersApi from './modules/custom-filters'
import getUserTypesApi from './modules/users-type'
import getOrderCommentsApi from './modules/order-comments'
import getSearchLogsApi from './modules/search-logs'
import getCollectionsApi from './modules/collections'
import getCalculationsApi from './modules/calculations'
import getEmailTemplateGroupsApi from './modules/email-template-groups'
import getRafflesApi from './modules/raffles'
import getDadataApi from './modules/dadata'
import getEventTriggersApi from './modules/event-triggers'
import getPageTemplatesApi from './modules/page-templates'
import getNewslettersApi from './modules/newsletters/index'
import getCalculatorElementsApi from './modules/calculator-elements'
import getCalculatorConditionsApi from './modules/calculator-conditions'
import getCalculatorsApi from './modules/calculators'
import getCalculatorTypesApi from './modules/calculator-types'
import getCurrencySourceApi from './modules/currency-source'
import getChatChannelApi from './modules/chat-channels'

import authModule from './modules/auth/store'
import widgetsModule from './modules/widgets/store'
import emailTemplatesModule from './modules/email-templates/store'
import subscribersModule from './modules/subscribers/store'
import configModule from './modules/config/store'
import paymentsModule from './modules/payments/store'
import discountGroupsModule from './modules/discount-groups/store'
import currenciesModule from './modules/currencies/store'
import bannerBlocksModule from './modules/banner-blocks/store'
import rolesModule from './modules/roles/store'
import sitesModule from './modules/sites/store'
import priceRolesModule from './modules/price-roles/store'
import priceRoleAssignmentModule from './modules/price-role-assignment/store'
import ordersModule from './modules/orders/store'
import stickersModule from './modules/stickers/store'
import productGroupsModule from './modules/product-groups/store'
import partnersModule from './modules/partners/store'
import deliveryGroupsModule from './modules/delivery-groups/store'
import archiveModule from './modules/archive/store'
import deliveryModule from './modules/delivery/store'
import formsModule from './modules/forms/store'
import formParamsModule from './modules/form-params/store'
import permissionsModule from './modules/permissions/store'
import filesModule from './modules/files/store'
import optionsValuesModule from './modules/options-values/store'
import reviewsModule from './modules/reviews/store'
import listWaitingModule from './modules/list-waiting/store'
import orderStatusesModule from './modules/order-statuses/store'
import orderStatusGroupsModule from './modules/order-status-groups/store'
import mainPageModule from './modules/main-page/store'
import usersModule from './modules/users/store'
import regionsModule from './modules/regions/store'
import seoPagesModule from './modules/seo-pages/store'
import productsModule from './modules/products/store'
import wordReplacesModule from './modules/word-replaces/store'
import searchTextReplaceModule from './modules/search-text-replace/store'
import wordPluralsModule from './modules/word-plurals/store'
import seoTextsModule from './modules/seo-texts/store'
import wordCasesModule from './modules/word-cases/store'
import wordAdjectivesModule from './modules/word-adjectives/store'
import templatesModule from './modules/templates/store'
import generateModule from './modules/generate/store'
import deliveryPricesModule from './modules/delivery-prices/store'
import robotsModule from './modules/robots/store'
import categoriesModule from './modules/categories/store'
import newsModule from './modules/news/store'
import pointsModule from './modules/points/store'
import productOffersModule from './modules/product-offers/store'
import basketsModule from './modules/baskets/store'
import pagesModule from './modules/pages/store'
import OrderBillsModule from './modules/order-bills/store'
import offersModule from './modules/offer/store'
import orderOffersModule from './modules/order-offers/store'
import fixedProductsModule from './modules/product-fixed/store'
import reindexerModule from './modules/reindexer/store'
import bonusModule from './modules/bonus/store'
import bonusAccountModule from './modules/bonus-account/store'
import customFiltersModule from './modules/custom-filters/store'
import bonusTypeModule from './modules/bonus-type/store'
import bonusLogModule from './modules/bonus-log/store'
import errorLogsModule from './modules/error-logs/store'
import feedsModule from './modules/feeds/store'
import promoCodesModule from './modules/promo-codes/store'
import userTypesModule from './modules/users-type/store'
import orderCommentsModule from './modules/order-comments/store'
import searchLogsModule from './modules/search-logs/store'
import collectionsModule from './modules/collections/store'
import calculationsModule from './modules/calculations/store'
import emailTemplateGroupsModule from './modules/email-template-groups/store'
import rafflesModule from './modules/raffles/store'
import eventTriggersModule from './modules/event-triggers/store'
import pageTemplatesModule from './modules/page-templates/store'
import newslettersModule from './modules/newsletters/store'
import calculatorElementsModule from './modules/calculator-elements/store'
import calculatorConditionsModule from './modules/calculator-conditions/store'
import calculatorsModule from './modules/calculators/store'
import calculatorTypesModule from './modules/calculator-types/store'
import chatChannelsModule from './modules/chat-channels/store'

import { initializeAxios } from '~/utils/api'

const accessor: Plugin = function (ctx, inject) {
  const { store, $axios } = ctx
  /**
   * * Инициализируем $axios для плагина
   */
  initializeAxios($axios)
  /**
   * * Инициализируем обработчик запросов
   */
  store.registerModule('auth', authModule)
  store.registerModule('bannerBlocks', bannerBlocksModule)
  store.registerModule('baskets', basketsModule)
  store.registerModule('bonus', bonusModule)
  store.registerModule('bonusAccount', bonusAccountModule)
  store.registerModule('bonusLog', bonusLogModule)
  store.registerModule('bonusType', bonusTypeModule)
  store.registerModule('categories', categoriesModule)
  store.registerModule('configsList', configModule)
  store.registerModule('currencies', currenciesModule)
  store.registerModule('delivery', deliveryModule)
  store.registerModule('deliveryGroups', deliveryGroupsModule)
  store.registerModule('archive', archiveModule)
  store.registerModule('deliveryPrices', deliveryPricesModule)
  store.registerModule('discountGroups', discountGroupsModule)
  store.registerModule('emailTemplates', emailTemplatesModule)
  store.registerModule('errorLogs', errorLogsModule)
  store.registerModule('feeds', feedsModule)
  store.registerModule('files', filesModule)
  store.registerModule('fixedProducts', fixedProductsModule)
  store.registerModule('formParams', formParamsModule)
  store.registerModule('forms', formsModule)
  store.registerModule('generate', generateModule)
  store.registerModule('listWaiting', listWaitingModule)
  store.registerModule('mainPage', mainPageModule)
  store.registerModule('news', newsModule)
  store.registerModule('offers', offersModule)
  store.registerModule('optionsValues', optionsValuesModule)
  store.registerModule('orderBills', OrderBillsModule)
  store.registerModule('orderOffers', orderOffersModule)
  store.registerModule('orderStatusGroups', orderStatusGroupsModule)
  store.registerModule('orderStatuses', orderStatusesModule)
  store.registerModule('orders', ordersModule)
  store.registerModule('pages', pagesModule)
  store.registerModule('partners', partnersModule)
  store.registerModule('payments', paymentsModule)
  store.registerModule('permissions', permissionsModule)
  store.registerModule('points', pointsModule)
  store.registerModule('priceRoleAssignment', priceRoleAssignmentModule)
  store.registerModule('priceRoles', priceRolesModule)
  store.registerModule('productGroups', productGroupsModule)
  store.registerModule('productOffers', productOffersModule)
  store.registerModule('products', productsModule)
  store.registerModule('promoCodes', promoCodesModule)
  store.registerModule('regions', regionsModule)
  store.registerModule('reindexer', reindexerModule)
  store.registerModule('reviews', reviewsModule)
  store.registerModule('robots', robotsModule)
  store.registerModule('roles', rolesModule)
  store.registerModule('searchTextReplace', searchTextReplaceModule)
  store.registerModule('seoPages', seoPagesModule)
  store.registerModule('seoTexts', seoTextsModule)
  store.registerModule('sites', sitesModule)
  store.registerModule('stickers', stickersModule)
  store.registerModule('subscribers', subscribersModule)
  store.registerModule('templates', templatesModule)
  store.registerModule('userTypes', userTypesModule)
  store.registerModule('users', usersModule)
  store.registerModule('widgets', widgetsModule)
  store.registerModule('wordAdjectives', wordAdjectivesModule)
  store.registerModule('customFilters', customFiltersModule)
  store.registerModule('wordCases', wordCasesModule)
  store.registerModule('wordPlurals', wordPluralsModule)
  store.registerModule('wordReplaces', wordReplacesModule)
  store.registerModule('orderComments', orderCommentsModule)
  store.registerModule('searchLogs', searchLogsModule)
  store.registerModule('collections', collectionsModule)
  store.registerModule('calculations', calculationsModule)
  store.registerModule('emailTemplateGroups', emailTemplateGroupsModule)
  store.registerModule('raffles', rafflesModule)
  store.registerModule('eventTriggers', eventTriggersModule)
  store.registerModule('pageTemplates', pageTemplatesModule)
  store.registerModule('newsletters', newslettersModule)
  store.registerModule('calculatorElements', calculatorElementsModule)
  store.registerModule('calculatorConditions', calculatorConditionsModule)
  store.registerModule('calculators', calculatorsModule)
  store.registerModule('calculatorTypes', calculatorTypesModule)
  store.registerModule('chatChannels', chatChannelsModule)

  /**
   * * Инициализация хранилища
   */
  // initialiseStores(store)
  /**
   * * Подключение всех store модулей
   */
  inject('auth', getAuthApi.call(ctx, getModule(authModule, store)))
  inject('payments', getPaymentsApi(getModule(paymentsModule, store)))
  inject('points', getPointsApi(getModule(pointsModule, store)))
  inject('widgets', gwtWidgetsApi(getModule(widgetsModule, store)))
  inject('emailTemplates', getEmailTemplatesApi(getModule(emailTemplatesModule, store)))
  inject('subscribers', getSubscribersApi(getModule(subscribersModule, store)))
  inject('discountGroups', getDiscountGroupsApi(getModule(discountGroupsModule, store)))
  inject('configsList', getConfigsApi.call(ctx, getModule(configModule, store)))
  inject('currencies', getCurrenciesApi(getModule(currenciesModule, store)))
  inject('bannerBlocks', getBannerBlocksApi(getModule(bannerBlocksModule, store)))
  inject('roles', getRolesApi(getModule(rolesModule, store)))
  inject('sites', getSitesApi(getModule(sitesModule, store)))
  inject('searchTextReplace', getSearchTextReplaceApi(getModule(searchTextReplaceModule, store)))
  inject('seoPages', getSeoPagesApi(getModule(seoPagesModule, store)))
  inject('priceRoles', getPriceRolesApi(getModule(priceRolesModule, store)))
  inject('priceRoleAssignment', getPriceRoleAssignmentApi(getModule(priceRoleAssignmentModule, store)))
  inject('stickers', getStickersApi(getModule(stickersModule, store)))
  inject('productGroups', getProductGroupsApi(getModule(productGroupsModule, store)))
  inject('products', getProductsApi(getModule(productsModule, store)))
  inject('orders', getOrdersApi.call(ctx, getModule(ordersModule, store)))
  inject('partners', getPartnersApi(getModule(partnersModule, store)))
  inject('deliveryGroups', getDeliveryGroupsApi(getModule(deliveryGroupsModule, store)))
  inject('archive', getArchiveApi(getModule(archiveModule, store)))
  inject('delivery', getDeliveryApi(getModule(deliveryModule, store)))
  inject('wordReplaces', getWordReplacesApi(getModule(wordReplacesModule, store)))
  inject('wordPlurals', getWordPluralsApi(getModule(wordPluralsModule, store)))
  inject('seoTexts', getSeoTextsApi(getModule(seoTextsModule, store)))
  inject('wordCases', getWordCasesApi(getModule(wordCasesModule, store)))
  inject('wordAdjectives', getWordAdjectivesApi(getModule(wordAdjectivesModule, store)))
  inject('templates', getTemplatesApi(getModule(templatesModule, store)))
  inject('generate', getGenerateApi(getModule(generateModule, store)))
  inject('deliveryPrices', getDeliveryPricesApi(getModule(deliveryPricesModule, store)))
  inject('robots', geRobotsApi(getModule(robotsModule, store)))
  inject('categories', getCategoriesApi(getModule(categoriesModule, store)))
  inject('feeds', getFeedsApi(getModule(feedsModule, store)))
  inject('forms', getFormsApi(getModule(formsModule, store)))
  inject('formParams', getFormParamsApi(getModule(formParamsModule, store)))
  inject('permissions', getPermissionsApi(getModule(permissionsModule, store)))
  inject('files', getFilesApi.call(ctx, getModule(filesModule, store)))
  inject('optionsValues', getOptionsValuesApi(getModule(optionsValuesModule, store)))
  inject('news', getNewsApi(getModule(newsModule, store)))
  inject('reviews', getReviewsApi(getModule(reviewsModule, store)))
  inject('listWaiting', getListWaitingApi(getModule(listWaitingModule, store)))
  inject('orderStatuses', getOrderStatusesApi(getModule(orderStatusesModule, store)))
  inject('orderStatusGroups', getOrderStatusGroupsApi(getModule(orderStatusGroupsModule, store)))
  inject('regions', getRegionsApi(getModule(regionsModule, store)))
  inject('mainPage', getMainPageApi(getModule(mainPageModule, store)))
  inject('productOffers', getProductOffersApi(getModule(productOffersModule, store)))
  inject('users', getUsersApi(getModule(usersModule, store)))
  inject('userTypes', getUserTypesApi(getModule(userTypesModule, store)))
  inject('baskets', getBasketsApi(getModule(basketsModule, store)))
  inject('pages', getPagesApi(getModule(pagesModule, store)))
  inject('orderBills', getOrderBillsApi.call(ctx, getModule(OrderBillsModule, store)))
  inject('offers', getOffersApi(getModule(offersModule, store)))
  inject('orderOffers', getOrderOffersApi.call(ctx, getModule(orderOffersModule, store)))
  inject('fixedProducts', getFixedProductsApi(getModule(fixedProductsModule, store)))
  inject('reindexer', getReindexerApi(getModule(reindexerModule, store)))
  inject('format', getFormatApi.call(ctx))
  inject('bonus', getBonusApi.call(ctx, getModule(bonusModule, store)))
  inject('bonusAccount', getBonusAccountApi(getModule(bonusAccountModule, store)))
  inject('bonusType', getBonusTypeApi(getModule(bonusTypeModule, store)))
  inject('promoCodes', getPromoCodesApi(getModule(promoCodesModule, store)))
  inject('bonusLog', getBonusLogApi.call(ctx, getModule(bonusLogModule, store)))
  inject('errorLogs', getErrorLogsApi(getModule(errorLogsModule, store)))
  inject('customFilters', getCustomFiltersApi(getModule(customFiltersModule, store)))
  inject('orderComments', getOrderCommentsApi.call(ctx, getModule(orderCommentsModule, store)))
  inject('searchLogs', getSearchLogsApi(getModule(searchLogsModule, store)))
  inject('collections', getCollectionsApi(getModule(collectionsModule, store)))
  inject('calculations', getCalculationsApi.call(ctx, getModule(calculationsModule, store)))
  inject('emailTemplateGroups', getEmailTemplateGroupsApi(getModule(emailTemplateGroupsModule, store)))
  inject('raffles', getRafflesApi.call(ctx, getModule(rafflesModule, store)))
  inject('dadata', getDadataApi.call(ctx))
  inject('eventTriggers', getEventTriggersApi.call(ctx, getModule(eventTriggersModule, store)))
  inject('pageTemplates', getPageTemplatesApi(getModule(pageTemplatesModule, store)))
  inject('newsletters', getNewslettersApi.call(ctx, getModule(newslettersModule, store)))
  inject('calculatorElements', getCalculatorElementsApi.call(ctx, getModule(calculatorElementsModule, store)))
  inject('calculatorConditions', getCalculatorConditionsApi.call(ctx, getModule(calculatorConditionsModule, store)))
  inject('calculators', getCalculatorsApi.call(ctx, getModule(calculatorsModule, store)))
  inject('calculatorTypes', getCalculatorTypesApi.call(ctx, getModule(calculatorTypesModule, store)))
  inject('currencySource', getCurrencySourceApi())
  inject('chatChannels', getChatChannelApi(getModule(chatChannelsModule, store)))
}
export default accessor
